import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  useColorModes,
  CHeaderText,
  CCard,
  CCardBody,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilContrast, cilMenu, cilMoon, cilSun, cilLanguage, cifGb, cifRu } from '@coreui/icons'
import { AppHeaderDropdown, AppHeaderSearch } from './header'

const AppHeader = () => {
  const headerRef = useRef()
  const { colorMode, setColorMode } = useColorModes('kanepro-ru-theme')
  const { i18n, t } = useTranslation()

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }),
  )

  setInterval(() => {
    setCurrentTime(
      new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }),
    )
  }, 60000)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })
  }, [])

  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="px-3" fluid>
        <CCard className="card-light w-100">
          <CCardBody className="d-flex px-4 py-2">
            <CHeaderToggler
              className={classNames('d-lg-none', {
                'prevent-hide': !sidebarShow,
              })}
              onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
              style={{ marginInlineStart: '-14px' }}
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
            {/*<CForm className="d-none d-sm-flex">
          <CInputGroup>
            <CInputGroupText id="search-addon" className="bg-body-secondary border-0 px-1">
              <CIcon icon={cilSearch} size="lg" className="my-1 mx-2 text-body-secondary" />
            </CInputGroupText>
            <CFormInput
              placeholder={t('search')}
              aria-label="Search"
              aria-describedby="search-addon"
              className="bg-body-secondary border-0 position-relative"
            />
          </CInputGroup>
        </CForm>*/}
            <AppHeaderSearch />
            <CHeaderNav className="ms-auto">
              {/*<CDropdown*/}
              {/*  variant="nav-item"*/}
              {/*  placement="bottom-end"*/}
              {/*  className="d-flex align-items-center"*/}
              {/*>*/}
              {/*  <CDropdownToggle caret={false}>*/}
              {/*    <CIcon icon={cilLanguage} size="lg" />*/}
              {/*  </CDropdownToggle>*/}
              {/*  <CDropdownMenu>*/}
              {/*    <CDropdownItem*/}
              {/*      active={i18n.language === 'en'}*/}
              {/*      className="d-flex align-items-center"*/}
              {/*      as="button"*/}
              {/*      onClick={() => i18n.changeLanguage('en')}*/}
              {/*    >*/}
              {/*      <CIcon className="me-2" icon={cifGb} size="lg" /> English*/}
              {/*    </CDropdownItem>*/}
              {/*    <CDropdownItem*/}
              {/*      active={i18n.language === 'ru'}*/}
              {/*      className="d-flex align-items-center"*/}
              {/*      as="button"*/}
              {/*      onClick={() => i18n.changeLanguage('ru')}*/}
              {/*    >*/}
              {/*      <CIcon className="me-2" icon={cifRu} size="lg" /> Русский*/}
              {/*    </CDropdownItem>*/}
              {/*  </CDropdownMenu>*/}
              {/*</CDropdown>*/}
              {/*<CDropdown*/}
              {/*  variant="nav-item"*/}
              {/*  placement="bottom-end"*/}
              {/*  className="d-flex align-items-center"*/}
              {/*>*/}
              {/*  <CDropdownToggle caret={false}>*/}
              {/*    {colorMode === 'dark' ? (*/}
              {/*      <CIcon icon={cilMoon} size="lg" />*/}
              {/*    ) : colorMode === 'auto' ? (*/}
              {/*      <CIcon icon={cilContrast} size="lg" />*/}
              {/*    ) : (*/}
              {/*      <CIcon icon={cilSun} size="lg" />*/}
              {/*    )}*/}
              {/*  </CDropdownToggle>*/}
              {/*  <CDropdownMenu>*/}
              {/*    <CDropdownItem*/}
              {/*      active={colorMode === 'light'}*/}
              {/*      className="d-flex align-items-center"*/}
              {/*      as="button"*/}
              {/*      type="button"*/}
              {/*      onClick={() => setColorMode('light')}*/}
              {/*    >*/}
              {/*      <CIcon className="me-2" icon={cilSun} size="lg" /> {t('light')}*/}
              {/*    </CDropdownItem>*/}
              {/*    <CDropdownItem*/}
              {/*      active={colorMode === 'dark'}*/}
              {/*      className="d-flex align-items-center"*/}
              {/*      as="button"*/}
              {/*      type="button"*/}
              {/*      onClick={() => setColorMode('dark')}*/}
              {/*    >*/}
              {/*      <CIcon className="me-2" icon={cilMoon} size="lg" /> {t('dark')}*/}
              {/*    </CDropdownItem>*/}
              {/*    <CDropdownItem*/}
              {/*      active={colorMode === 'auto'}*/}
              {/*      className="d-flex align-items-center"*/}
              {/*      as="button"*/}
              {/*      type="button"*/}
              {/*      onClick={() => setColorMode('auto')}*/}
              {/*    >*/}
              {/*      <CIcon className="me-2" icon={cilContrast} size="lg" /> Auto*/}
              {/*    </CDropdownItem>*/}
              {/*  </CDropdownMenu>*/}
              {/*</CDropdown>*/}
              {/*<li className="nav-item py-1">*/}
              {/*  <div className="vr h-100 mx-2 text-body text-opacity-75"></div>*/}
              {/*</li>*/}
              <CHeaderText className="p-0 text-primary fw-semibold fs-4 d-flex align-items-center">
                {currentTime}
              </CHeaderText>
              <AppHeaderDropdown />
            </CHeaderNav>
          </CCardBody>
        </CCard>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
