import React from 'react'

const CookiePolicy = () => {
  return (
    <div className="container">
      <h1 className="fw-bold">Политика в отношении cookie-файлов</h1>

      <p>
        На сайте{' '}
        <a href="https://kanepro.ru/" target="_blank" rel="noreferrer">
          https://kanepro.ru/
        </a>{' '}
        (далее – «Сайт») мы используем cookie-файлы. Настоящая Политика предоставляет вам информацию
        о том, как мы используем cookie-файлы и как вы можете их контролировать.
      </p>
      <p>
        Используя Сайт, вы принимаете правила использования cookie-файлов (и схожих технологий) в
        соответствии с настоящим документом. В частности, вы принимаете правила использования
        аналитических, рекламных и функциональных cookie-файлов для целей, указанных ниже.
      </p>
      <h3 className="fw-bold">Как мы используем cookie-файлы?</h3>
      <p>
        Информация, сбор которой осуществляется с помощью cookie-файлов, помогает анализировать
        процесс использование Сайта пользователями для обеспечения максимального удобства, а также
        сбора статистики. Мы также может передавать полученные данные третьим лицам в обезличенном
        виде для проведения исследований, выполнения работ или оказания услуг.
      </p>
      <h3 className="fw-bold">Какие типы cookie-файлов мы используем?</h3>
      <h5 className="fw-bold">Технические cookie-файлы</h5>
      <p>
        Использование этих функциональных cookie-файлов нельзя запретить при посещении нашего сайта,
        так как они обеспечивают корректную работу веб-сайта. Сюда относятся cookie-файлы, которые
        собирают информацию о том, разрешено или запрещено использование других cookie-файлов с
        нашего сайта.
      </p>
      <h5 className="fw-bold">Аналитические файлы cookie</h5>
      <p>
        Мы используем средства веб-аналитики с целью общего анализа использования Сайта и получения
        данных для формирования персональных предложений. Полученная при этом информация может
        передаваться в анонимной форме на сервер службы веб-аналитики, сохраняться и обрабатываться
        там. Мы можем использовать аналитические инструменты и соответствующие cookie-файлы
        поставщика услуг Яндекс.Метрика:{' '}
        <a href="https://yandex.ru/legal/confidential/" target="_blank" rel="noreferrer">
          Политика конфиденциальности Яндекс
        </a>
      </p>
      <h3 className="fw-bold">Как можно разрешить или запретить использование cookie-файлов?</h3>
      <p>
        Большинство браузеров автоматически принимают cookie-файлы. Вы можете удалить сохраненные
        cookie-файлы в любое время с вашего устройства, воспользовавшись инструкцией по
        использованию браузера или устройства.
      </p>
      <p>
        В настройках браузера также можно запретить любые cookie-файлы или определенные типы (в
        зависимости от используемого браузера для запрета рекомендуем воспользоваться инструкцией).
      </p>
      <p>
        Обращаем внимание что, отключив cookie-файлы, вы не сможете пользоваться некоторыми
        функциями, сервисами, инструментами и возможностями нашего Сайта.
      </p>
    </div>
  )
}

export default CookiePolicy
