import React, { useState } from 'react'
import { CFooter } from '@coreui/react-pro'
import { ModalMessage } from '../components'
import { Link } from 'react-router-dom'
import CookiePolicy from '../views/pages/policies/CookiePolicy'

const AppFooter = () => {
  const [showCookiePolicy, setShowCookiePolicy] = useState(false)

  return (
    <>
      <CFooter className="px-4 d-flex justify-content-between align-items-center flex-column flex-md-row bg-body-tertiary">
        <div className="fs-7 mb-2 mb-md-0">
          <Link
            className="text-decoration-none"
            onClick={() => {
              setShowCookiePolicy(true)
            }}
          >
            Политика в отношении файлов cookie
          </Link>
        </div>
        <div>2025 &copy; KaneProfessional</div>
      </CFooter>
      <ModalMessage
        message={showCookiePolicy}
        component={<CookiePolicy />}
        onClose={() => setShowCookiePolicy(false)}
        size="xl"
        backdrop="static"
      />
    </>
  )
}

export default React.memo(AppFooter)
