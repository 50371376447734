import React, { Suspense, useEffect, useState } from 'react'
import { useLocation, HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ProtectedRoute } from './layout/index'
import { AuthProvider, useAuth } from './hooks/useAuth'
import { CookieConsent } from 'react-cookie-consent'
import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'
import { ModalMessage } from './components'

// We use those styles to show code examples, you should remove them in your application.
// import './scss/examples.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const CookiePolicy = React.lazy(() => import('./views/pages/policies/CookiePolicy'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const YmTrackPageView = () => {
  const location = useLocation()

  useEffect(() => {
    window.ym(97789007, 'hit', window.location.href)
  }, [location.pathname])

  return null
}

const YmTrackRoles = () => {
  //Передает параметры в Яндекс.Метрику для сегментации пользователей по ролям
  const { Roles, checkRoles } = useAuth()
  if (checkRoles(Roles.ADMIN)) {
    window.ym(97789007, 'params', { role: 'АДМИН' })
  }
  if (checkRoles(Roles.INSTRUCTOR)) {
    window.ym(97789007, 'params', { role: 'ИНСТРУКТОР' })
  }

  return null
}

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('kanepro-ru-theme')
  const storedTheme = useSelector((state) => state.theme)
  const [showCookiePolicy, setShowCookiePolicy] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    // принудительно тема, пока без переключателя
    setColorMode('light')

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <HashRouter>
        <YmTrackPageView />
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <AuthProvider>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/privacy" name="Privacy" element={<CookiePolicy />} />
              {/*<Route exact path="/register" name="Register Page" element={<Register />} />*/}
              {/*<Route exact path="/404" name="Page 404" element={<Page404 />} />*/}
              {/*<Route exact path="/500" name="Page 500" element={<Page500 />} />*/}
              <Route
                path="*"
                name="Home"
                element={
                  <ProtectedRoute>
                    <DefaultLayout />
                    <YmTrackRoles />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </Suspense>
      </HashRouter>

      <CookieConsent
        location="bottom"
        buttonText="Принять"
        cookieName="cookie_consent"
        containerClasses="shadow-lg p-2 bg-body border rounded start-50 translate-middle"
        contentClasses="text-dark"
        disableButtonStyles={true}
        style={{
          zIndex: 1050,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '90%',
        }}
        buttonClasses="btn btn-outline-primary m-3"
        expires={365}
      >
        Наш сайт использует файлы cookies. Продолжая им пользоваться, вы соглашаетесь с{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setShowCookiePolicy(true)
          }}
        >
          политикой обработки cookies
        </a>
        .
      </CookieConsent>
      <ModalMessage
        message={showCookiePolicy}
        component={<CookiePolicy />}
        onClose={() => setShowCookiePolicy(false)}
        size="xl"
        backdrop="static"
      />
    </>
  )
}

export default App
